import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const BannerAlertBox = makeShortcode("BannerAlertBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "trip-patterns-api"
    }}>{`Trip patterns API`}</h1>
    <BannerAlertBox variant="warning" mdxType="BannerAlertBox">
    <p>
        The v2 API is now deprecated, and all consumers should start migrating
        To the <a href="/pages-journeyplanner-journeyplanner">v3 API</a>. The
        end-of-life date for v2 is set to September 1, 2022.
    </p>
    </BannerAlertBox>
    <BannerAlertBox variant="warning" mdxType="BannerAlertBox">
    <p>
        The Journey Planner v3 API has no direct replacement for replace-leg functionality. You should
        use `nextLegs` and/or `previousLegs` to fetch additional departures, and implement the replace
        logic yourself.
    </p>
    </BannerAlertBox>
    <p>{`Utility service for trip patterns. Contains only one endpoint:`}</p>
    <h2 {...{
      "id": "replace-leg"
    }}>{`replace-leg`}</h2>
    <p>{`Replace a transit leg in an existing trip-pattern. Used to find more departures for a portion of a trip-pattern without
affecting the rest. Results in a new list of trip-patterns.`}</p>
    <p>{`The replace-leg api can be used to add a stopover at an intermediate stop in the journey,
or to allow more time for interchange.`}</p>
    <h3 {...{
      "id": "limitations"
    }}>{`Limitations`}</h3>
    <p>{`Original requests with vias argument set is currently not supported.`}</p>
    <h3 {...{
      "id": "how-to-use"
    }}>{`How to use`}</h3>
    <p>{`Send a http `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request to the following URL:`}</p>
    <pre><code parentName="pre" {...{}}>{`  https://api.entur.io/journey-pattern/v2/trip-patterns/replace-leg
`}</code></pre>
    <p>{`with content-type `}<inlineCode parentName="p">{`application/json`}</inlineCode>{`. The body of the request should be a JSON structure with the following
properties:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`originalTripPattern`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The tripPattern to be manipulated by replacing leg.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`originalRequest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The request used to fetch originalTripPattern from the journeyplanner API`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`replaceLegServiceJourneyId`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Id for the ServiceJourney to replace. There has to be one leg within originalTripPattern with a ServiceJourney with this id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`numEarlierTripPatterns`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The number of tripPatterns with earlier departure times to fetch`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`numLaterTripPatterns`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The number of tripPatterns with later arrival times to fetch`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`All properties are mandatory, except `}<inlineCode parentName="p">{`numEarlierTripPatterns`}</inlineCode>{` and `}<inlineCode parentName="p">{`numLaterTripPatterns`}</inlineCode>{`, of which you must provide
at least one. `}</p>
    <h4 {...{
      "id": "originaltrippattern"
    }}><inlineCode parentName="h4">{`originalTripPattern`}</inlineCode></h4>
    <p>{`In the journey planner API response you will typically get a list of (one or more) trip-patterns in the response:`}</p>
    <pre><code parentName="pre" {...{}}>{`   {
        "data": {
            "trip": {
                "tripPatterns": [ ... ]
            }
        }
   }
`}</code></pre>
    <p>{`You will need to include the whole trip pattern object from this response and it must include certain properties
in order for this API to be able to replace a leg:`}</p>
    <pre><code parentName="pre" {...{}}>{`    fromPlace.quay.stopPlace.id
    toPlace.quay.stopPlace.id
`}</code></pre>
    <h4 {...{
      "id": "originalrequest"
    }}><inlineCode parentName="h4">{`originalRequest`}</inlineCode></h4>
    <p>{`The original graphql request must be provided in the following way:`}</p>
    <pre><code parentName="pre" {...{}}>{`    {
        "query": "...",
        "variables: { ... }
    }
`}</code></pre>
    <p>{`The query value must be the original graphql query, and the variables value must provide any variables used in the query.`}</p>
    <p>{`It's important that the query specifies the original fields and must match the fields in `}<inlineCode parentName="p">{`originalTripPattern`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "example-request-body"
    }}>{`Example request body`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "replaceLegServiceJourneyId": "SJN:ServiceJourney:SJN_47_2021_02_23_NORD",
    "originalTripPattern": {
        "legs": [
            {
                "aimedEndTime": "2021-02-23T22:49:00+0100",
                "aimedStartTime": "2021-02-23T16:02:00+0100",
                "expectedEndTime": "2021-02-23T22:49:00+0100",
                "expectedStartTime": "2021-02-23T16:02:00+0100",
                "fromPlace": {
                    "name": "Oslo S",
                    "quay": {
                        "stopPlace": {
                            "id": "NSR:StopPlace:337"
                        }
                    }
                },
                "mode": "rail",
                "serviceJourney": {
                    "id": "SJN:ServiceJourney:SJN_47_2021_02_23_NORD"
                },
                "toPlace": {
                    "name": "Trondheim S",
                    "quay": {
                        "stopPlace": {
                            "id": "NSR:StopPlace:659"
                        }
                    }
                },
                "transportSubmode": "regionalRail"
            },
            {
                "aimedEndTime": "2021-02-23T22:49:07+0100",
                "aimedStartTime": "2021-02-23T22:49:00+0100",
                "expectedEndTime": "2021-02-23T22:49:07+0100",
                "expectedStartTime": "2021-02-23T22:49:00+0100",
                "fromPlace": {
                    "name": "Trondheim S",
                    "quay": {
                        "stopPlace": {
                            "id": "NSR:StopPlace:659"
                        }
                    }
                },
                "mode": "foot",
                "serviceJourney": null,
                "toPlace": {
                    "name": "Trondheim S",
                    "quay": {
                        "stopPlace": {
                            "id": "NSR:StopPlace:659"
                        }
                    }
                },
                "transportSubmode": null
            },
            {
                "aimedEndTime": "2021-02-24T09:17:00+0100",
                "aimedStartTime": "2021-02-23T23:27:00+0100",
                "expectedEndTime": "2021-02-24T09:17:00+0100",
                "expectedStartTime": "2021-02-23T23:27:00+0100",
                "fromPlace": {
                    "name": "Trondheim S",
                    "quay": {
                        "stopPlace": {
                            "id": "NSR:StopPlace:659"
                        }
                    }
                },
                "mode": "rail",
                "serviceJourney": {
                    "id": "SJN:ServiceJourney:SJN_475_2021_02_23_NORD"
                },
                "toPlace": {
                    "name": "Bodø stasjon",
                    "quay": {
                        "stopPlace": {
                            "id": "NSR:StopPlace:507"
                        }
                    }
                },
                "transportSubmode": "nightRail"
            }
        ]
    },
    "originalRequest": {
        "query": "query ($numTripPatterns: Int!, $from: Location!, $to: Location!, $dateTime: DateTime!, $arriveBy: Boolean!, $wheelchair: Boolean!, $modes: [Mode]!, $transportSubmodes: [TransportSubmodeFilter], $maxPreTransitWalkDistance: Float, $walkSpeed: Float, $minimumTransferTime: Int, $allowBikeRental: Boolean, $useFlex: Boolean, $banned: InputBanned, $whiteListed: InputWhiteListed) {\\n  trip(\\n    numTripPatterns: $numTripPatterns,\\n    from: $from,\\n    to: $to,\\n    dateTime: $dateTime,\\n    arriveBy: $arriveBy,\\n    wheelchair: $wheelchair,\\n    modes: $modes,\\n    transportSubmodes: $transportSubmodes,\\n    maxPreTransitWalkDistance: $maxPreTransitWalkDistance,\\n    walkSpeed: $walkSpeed,\\n    minimumTransferTime: $minimumTransferTime,\\n    allowBikeRental: $allowBikeRental,\\n    useFlex: $useFlex,\\n    banned: $banned,\\n    whiteListed: $whiteListed\\n  ) {\\n    tripPatterns {\\n      expectedStartTime\\n      expectedEndTime\\n      directDuration\\n      duration\\n      distance\\n      walkDistance\\n      legs {\\n        ...legFields\\n      }\\n    }\\n  }\\n}\\n\\nfragment legFields on Leg {\\n  aimedEndTime\\n  aimedStartTime\\n  expectedEndTime\\n  expectedStartTime\\n  fromPlace {\\n    ...placeFields\\n  }\\n  mode\\n  serviceJourney {\\n    ...serviceJourneyFields\\n  }\\n  toPlace {\\n    ...placeFields\\n  }\\n  transportSubmode\\n}\\n\\nfragment placeFields on Place {\\n  name\\n  quay {\\n    stopPlace {\\n      id\\n    }\\n  }\\n}\\n\\nfragment serviceJourneyFields on ServiceJourney {\\n  id\\n}\\n",
        "variables": {
            "walkSpeed": 1.3,
            "minimumTransferTime": 120,
            "useFlex": false,
            "maxPreTransitWalkDistance": 2000,
            "from": {
                "name": "Oslo S, Oslo",
                "place": "NSR:StopPlace:59872"
            },
            "to": {
                "name": "Bodø stasjon, Bodø",
                "place": "NSR:StopPlace:58952"
            },
            "dateTime": "2021-02-23T09:22:58.297+01:00",
            "arriveBy": false,
            "modes": [
                "foot",
                "coach",
                "bus",
                "tram",
                "rail",
                "metro",
                "water"
            ],
            "transportSubmodes": [],
            "wheelchair": false,
            "numTripPatterns": 5
        }
    },
    "numEarlierTripPatterns": 2
}
`}</code></pre>
    <h3 {...{
      "id": "response"
    }}>{`Response`}</h3>
    <pre><code parentName="pre" {...{}}>{`   {
        "data": {
            "trip": {
                "tripPatterns": <list of trip patters with leg replaced>
            }
        }
   }
`}</code></pre>
    <h3 {...{
      "id": "implementation-details"
    }}>{`Implementation details`}</h3>
    <p>{`The replace-leg API uses a request that you have already made to the journey planner API as follows:`}</p>
    <ol>
      <li parentName="ol">
        <ul parentName="li">
          <li parentName="ul">{`If `}<inlineCode parentName="li">{`numEarlierTripPatterns`}</inlineCode>{` is specified, re-send the original request to the journey planner API using the
specified leg's end-point (toPlace) as the new end-point and find `}<inlineCode parentName="li">{`numEarlierTripPatterns`}</inlineCode>{` additional trip
patterns (numTripPatterns).`}</li>
          <li parentName="ul">{`Take the legs from the original trip pattern after the leg to be replaced and push them to the end of the list of
legs in each new trip pattern.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <ul parentName="li">
          <li parentName="ul">{`If `}<inlineCode parentName="li">{`numLaterTripPatterns`}</inlineCode>{` is specified, re-send the original request to the journey planner API using the
specified leg's start-point (fromPlace) as the new start-point and find `}<inlineCode parentName="li">{`numLaterTripPatterns`}</inlineCode>{` additional trip
patterns (numTripPatterns).`}</li>
          <li parentName="ul">{`Take the legs from the original trip pattern before the leg to be replaced and push the to the beginning of the
list of legs in each new trip pattern`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Concatenate the lists from steps 1 and 2 (one of which may be empty)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Return the list in a wrapped response`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      